@media (max-width: 1399px) {
  .leafs {
    bottom: 23px !important;
    left: 23px !important;
  }

  .white-leaf {
    top: 78px !important;
    right: 15px !important;
  }

  // #home {
  //   min-height: 490px !important;
  // }
  .stepsTwo {
    left: 330px;
  }

  .stepsThree {
    left: 670px;
  }

  .stepsFour {
    left: 295px;
  }
}

@media (max-width: 1199px) {
  .logo svg {
    width: 140px !important;
  }

  .nav-bar-list {
    gap: 20px !important;
  }

  .footer-content>svg {
    width: 150px !important;
  }

  .footer-logo svg {
    width: 150px !important;
  }

  .footer-content>.gap-3 {
    gap: 0px !important;
  }

  .stepsTwo {
    left: 160px;
  }

  .stepsTwo .stepNumImg {
    left: 160px;
    top: 60px;
  }

  .stepsThree {
    left: 490px;
    top: 40px;
  }

  .stepsFour {
    top: 220px;
    left: 75px;
  }

  .stepsFive {
    left: 180px;
  }

  .headingProcessCon {
    padding-right: 60px !important;
    ;
  }
}

@media (max-width: 991px) {
  .social-link {
    display: none;
  }

  .nav-bar-list {
    display: none !important;
  }

  .toggleBtn {
    margin: 0 5px;
    display: block !important;
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
  }

  .toggleBtn svg {
    color: var(--white);
  }

  .showToggle {
    position: absolute !important;
    top: 87px;
    left: 0;
    background: var(--themeColor) !important;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.18, 1);
    color: var(--white) !important;
    width: 100% !important;
    display: block !important;
    box-shadow: 0px 5px 15px #0ea3594f;
  }

  .showToggle div {
    border-bottom: 1px solid var(--white);
    outline: none;
    width: 100%;
    padding: 20px 10px;
  }

  .showToggle a:hover {
    color: var(--darkTextGradient);
  }

  .showToggle .active {
    color: var(--darkTextGradient);
  }

  .showToggle .nav-bar-list a {
    color: var(--darkTextGradient);
    background: none !important;

    &:focus,
    &::before,
    &.active::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      width: 0;
      height: 0;
      background: transparent !important;
    }
  }

  .nav-bar-list a.active::before {
    background: transparent !important;
  }

  .sticky.py-2 {
    transition: all 0.4s ease-in-out;
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
  }

  // nenu-responsive end

  .animated-wrapper {
    bottom: 26px !important;
    left: -25px !important;
  }

  .about-content .text-end,
  .about-content .text-start,
  .text-end {
    text-align: center !important;
  }

  .about-content .founder {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
  }

  .bird {
    left: -90px !important;
  }

  .client-wrap {
    grid-template-columns: 1fr 1fr 1fr !important;
  }

  .item-1 {
    grid-column: 2 / 2 !important;
    padding: 10px;
  }


  .client-content {
    width: 100% !important;
  }

  .contact-wrapper {
    flex-wrap: wrap !important;
  }

  .contect-with-us {
    width: 100% !important;

    &>div {
      width: 100% !important;
    }
  }

  .contact-info {
    &>div {
      // text-align: center !important;
      // justify-content: center !important;

    }
  }

  .follow {
    &>div {
      // text-align: center !important;
      // justify-content: center !important;

    }

    margin-bottom:20px;
  }

  .contact-info::before {
    content: "";
    left: 30px !important;
    right: 0 !important;
    margin: auto !important;
  }

  .contect-with-you {
    width: 100% !important;
  }

  iframe {
    width: 100% !important;
  }

  .footer-content {
    flex-direction: column !important;
    // gap: 0 !important;
  }

  .visibility {
    display: none;
  }

  #projects .common-space {
    padding: 20px 0 !important;
  }

  .fiveStepH {
    display: flex !important;

  }

  .fiveStepH .headingProcessCon {
    padding-right: 0 !important;
    text-align: center
  }

  .fiveStepH2 {
    display: none !important;
  }

  .processFormHeadingContainer {
    justify-content: center;
  }

  .stepsOne {
    left: 0;
    right: 0;
    margin: auto;
  }

  .stepsTwo .stepArrows {
    top: 70px;
    left: auto;
    right: 200px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .stepsTwo .stepNumImg {
    left: -80px;
    top: 60px;
  }

  .stepsThree {
    left: 230px;
    top: 110px;
  }

  .stepsFour .stepArrows {
    top: 170px;
    width: 540px;
  }

  .stepsFour .stepNumImg {
    left: -40px;
    top: 110px;
  }

  .stepsFive {
    left: 80px;
    top: 530px;
  }

  .stepsFive .stepArrows {
    top: 0px;
    width: 400px;
  }

  .stepsFive .stepNumImg {
    left: 400px;
    top: 60px;
  }

  .stepContactWrapper {
    height: 840px;
  }

  .processFormWrapper {
    padding-bottom: 830px !important;
  }

  .taglineAnimContainer {
    left: -10px !important;
    top: -13px !important;
  }

  .visionBox {
    padding-right: 0px !important;
    padding-bottom: 40px;
  }

}

@media (max-width: 768px) {

  .bird {
    left: 0px !important;
    width: 25%;
    bottom: -50px !important;
  }

  .common-space {
    padding: 35px 0;
  }

  .plant span {
    top: -60px !important;
  }

  .client-wrap {
    grid-template-columns: 1fr 1fr !important;
  }

  .client-content {
    align-items: center !important;
  }

  .client-content p {
    text-align: center !important;
  }

  .client-bg {
    padding: 50px 0px !important;
  }

  .slick-prev,
  .slick-next {
    top: auto !important;
    bottom: -5% !important;
    right: 0;
    left: 0;
    margin: auto;
  }

  .slick-prev {
    right: 60px !important;
    z-index: 2;
  }

  .slick-next {
    left: 60px !important;
    z-index: 2;
  }

  .contact-img {
    bottom: 20% !important;
  }

  .processFormWrapper {
    padding-bottom: 860px !important;
  }
}

@media (max-width: 767px) {
  .stepImgRound {
    width: 80px;
  }

  .StepNumbers {
    font-size: 60px !important;
    line-height: 50px !important;
  }

  /* .stepNumImg span {
		top: 36px;
		left: -16px;
	}
	.StepNumbersTxt {
		font-size: 16px;
		line-height: 18px;
	}
	.stepsTwo {
		left: 28px;
	}
	.stepsTwo .stepArrows {
		right: 120px;
	}
	.stepsTwo .stepNumImg {
		left: 60px;
		top: 90px;
	}
	.stepsThree {
		left: 160px;
		top: 50px;
	  }
	  .stepsThree .stepNumImg {
		left: 230px;
		top: 80px;
	  } */
  .stepArrows {
    display: none;
  }

  .stepContact {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    height: auto;
    margin-bottom: 30px;
  }

  .stepContact>*,
  .stepNumImg span {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
  }

  .stepNumImg {
    // text-align: center;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 290px;
  }

  .stepNumbersWrap {
    order: 2;
    display: flex;
  }

  .StepNumbersTxt {
    width: 250px !important;
    text-align: center !important;
  }

  .processFormWrapper {
    padding-top: 70px !important;
    padding-bottom: 470px !important;
  }

  .stepContactWrapper {
    height: 470px !important;
    top: 560px;
  }

  #home {
    max-height: 100vh;
    min-height: auto;
    height: 500px;
  }

  .banner-layers {
    max-height: 100vh;
    min-height: auto !important;
    height: 500px !important;
    padding-top: 110px;
  }

  .down-btn {
    bottom: -50px !important;
  }

  .down-btn-all {
    bottom: auto !important;
  }

  .down-btn-projects {
    bottom: -40px !important;
  }

  .down-btn-steps {
    bottom: 15px !important;
  }

  .about-content {
    width: calc(100% + 24px) !important;
    left: -12px !important;
    top: 0px !important;
    border-radius: 0 !important;
  }

  .stepNumbersWrapTwo {
    top: 0;
    right: 0;
  }

  .stepNumbersWrapThree {
    top: 0;
    right: 0;
  }

  .stepNumbersWrapFour {
    top: 0;
    right: 0;
  }

  .stepNumbersWrapFive {
    top: 0;
    left: 0;
  }

  .stepNumbersWrapTwo,
  .stepNumbersWrapThree,
  .stepNumbersWrapFour,
  .stepNumbersWrapFive {
    flex-direction: row !important;
    justify-content: center !important;
    text-align: center !important;
  }

}


@media (max-width: 576px) {

  .nav-bar button,
  .about-social-link {
    display: none !important;
  }

  .about-bg .common-space {
    padding: 0 !important;
  }


  .about-content .w-50 {
    width: 100% !important;
  }

  p {
    font-size: 14px !important;
  }


  button {
    font-size: 12px !important;
  }

  //   #projects .common-space {
  //     padding: 20px 0 !important;
  //   }

  .footer-list {
    display: none !important;
  }

  .client-content {
    padding: 0 !important;
  }

  .footer-revised {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .slick-prev,
  .slick-next {
    width: 40px !important;
    height: 40px !important;
    bottom: 50% !important;
  }

  .slick-next {
    left: 360px !important;
  }

  .slick-prev {
    right: 360px !important;

  }

  .loading-btn {
    top: 50% !important;
    height: 9% !important;
    width: 100% !important;
  }

  .main-heading {
    font-size: 35px !important;
  }

  p>span {
    font-size: 16px !important;
  }

  .about-content .row {
    text-align: center !important;

    & .button1 {
      display: flex;
      justify-content: center;
    }
  }

  #vision .row {
    text-align: center !important;

    & span {
      display: flex;
      justify-content: center;
    }

  }

  .service-list {
    // text-align: center !important;
    // align-items: center !important;
    margin: 15px !important;
  }

  .client-wrap {
    grid-template-columns: 1fr !important;
  }

  .item-1 {
    grid-column: 1 / 1 !important;
  }

  .contact-box {
    padding: 35px 0 0 !important;
  }

  #contact {
    padding-top: 20px !important;
  }

  .footer-content {
    gap: 20px !important;
  }

  .contact-img {
    width: 35%;
  }

  .plant {

    & img {
      width: 20%;
      top: -20px;
    }
  }

  .plant span {
    top: -15px !important;
  }

  .footer-revised {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .light-logo {
    & img {

      visibility: hidden;
    }
  }

  .animated-container {
    width: 310px;
  }

  .bottom-leaf {
    bottom: 0px !important;
    right: -25px !important;
    width: 25% !important;
  }

  .animated-wrapper {
    top: -20px !important;
    left: -20px !important;
  }

  .projects-content {
    width: 60% !important;
    margin: auto;
  }

  .bZTAeq .banner-layers {
    max-height: 100vh;
    min-height: 460px;
    margin-top: 50px;
  }

  .processFormContainer {
    max-width: 100% !important;
    min-width: 100% !important;
  }

  .field-box-wh {
    flex-wrap: wrap;
  }

  .field-box-wh .field-box {
    width: 100%;

  }

  .field-box-wh .field-box:first-child {
    margin-bottom: 0;

  }

  .field-box-wh .field-box:nth-child(2) {
    margin-top: 0;

  }

  .stepContactWrapper {
    top: 620px !important;
    height: 300px !important;
  }

  .taglineAnimContainer {
    left: -13px !important;
    top: -40px !important;
  }

  .contact-wrapper {
    padding: 0 !important;
  }



  .processFormWrapper {
    padding-top: 70px !important;
    padding-bottom: 330px !important;
  }

  .stepsOne {
    position: absolute;
    top: 25px;
    width: 300px;
  }
}



@media (max-width: 480px) {

  .contact-wrapper {
    bottom: 30px !important;
  }

  .footer-list {
    gap: 5px 50px !important;
    flex-wrap: wrap !important;
  }

  .footer-revised {
    text-align: center;
  }

  //   #projects .common-space {
  //     padding: 0px 0 !important;
  //   }

  .whatsapp,
  .change-Btn {
    width: 60px !important;
  }

  /*  .animated-container {
    text-align: center;
    justify-content: center;
    display: flex;
  } */

  // .animated-container {
  //   width: 50%;
  // }
  .animated-container {
    width: 250px;
  }

  .animated-items {
    bottom: 12px !important;
  }

  .leafs {
    bottom: 20px !important;
    left: 38px !important;
  }

  .white-leaf {
    top: 50px !important;
    right: 15px !important;
  }

  .top-leaf {
    width: 25%;
  }

  .taglineAnimContainer {
    top: -90px !important;
  }

  .slick-prev,
  .slick-next {
    width: 35px !important;
    height: 35px !important;
    // bottom: 50% !important;
  }

  .slick-next {
    left: 270px !important;
  }

  .slick-prev {
    right: 270px !important;
  }

  .projects-content {
    width: 70% !important;
    margin: auto;
  }

  // .light-logo,
  // .white-leaf,
  // .green-leaf,
  // .main-deyam,
  // .dots,
  // .text-main {
  //   width: 55% !important;
  // }


  // .leafs {
  //   bottom: 60px !important;
  //   left: 10px !important;
  // }

  // .white-leaf {
  //   top: 45px !important;
  //   right: 76px !important;
  // }

  // .main-deyam {
  //   height: auto !important;
  //   top: -21px !important;
  //   left: 105px !important;
  // }

  .dots {
    position: relative;
    top: 5px;

    & img {
      width: 30px !important;
    }
  }

  // .text-main {
  //   position: relative;
  //   top: -47px;
  //   left: 100px;
  // }

  .down-btn a {
    bottom: -150px !important;
  }

  .logo svg {
    width: 100px !important;
  }

  .footer-logo svg {
    width: 120px !important;
  }


  #home {
    max-height: 100vh;
    min-height: auto;
    height: 450px;
  }

  .banner-layers {
    max-height: 100vh;
    min-height: auto !important;
    height: 450px !important;
    padding-top: 110px;
  }

  .down-btn {
    bottom: -50px !important;
  }

  .down-btn-all {
    bottom: auto !important;
  }

  .down-btn-projects {
    bottom: -40px !important;
  }

  .down-btn-steps {
    bottom: 15px !important;
  }

  // .animated-wrapper {
  //   top: 60px !important;
  //   left: -25px !important;
  // }

  //   .taglineAnimContainer {
  // 	left: -13px;
  // 	top: -40px;
  //   }
}

@media (max-width: 400px) {
  // .animated-wrapper {

  //   top: 57px !important;
  //   left: -10px !important;
  // }

  // .light-logo {
  //   & img {

  //     visibility: hidden;
  //   }
  // }
  .animated-wrapper {
    top: -24px !important;
    left: -20px !important;
  }

  .down-btn a {
    width: 10px !important;
    bottom: -180px !important;
  }

  .main-heading {
    font-size: 28px !important;
  }

  .footer-content {
    // gap: 0px !important;
  }

  // .plant span {
  //   top: -26px !important;
  // }
  .plant {

    & img {
      width: 20%;
      top: 0px;
    }
  }
}

/***************** RTL DIrection*************************/

@media (max-width: 1399px) {
  .rtl .stepsTwo {
    left: 360px;
  }

  .rtl .stepsThree {
    left: 25px;
  }

  .rtl .stepsFour {
    left: 235px;
  }

  .rtl .stepsFive {
    left: 210px;
  }
}

@media (max-width: 1199px) {

  .rtl .footer-logo svg,
  .rtl .footer-content svg {
    width: 150px !important;
  }

  .rtl .headingProcessCon {
    padding-left: 0;
  }

  .rtl .stepsThree {
    left: 25px;
  }

  .rtl .stepsFour {
    top: 220px;
    left: 205px;
  }

  .rtl .stepsFour {
    left: 205px;
  }


}

@media (max-width: 991px) {
  .rtl .bird {
    right: 0px;
  }

  .rtl .stepsTwo {
    left: -10px;
  }

  .rtl .stepsThree .stepArrows {
    right: 0;
    transform: scaleX(1) !important;
    -webkit-transform: scaleX(1) !important;
    -moz-transform: scaleX(1) !important;
    -ms-transform: scaleX(1) !important;
    -o-transform: scaleX(1) !important;
  }

  .rtl .stepsThree {
    left: 60px;
  }

  .rtl .stepsThree .stepNumImg {
    left: 350px;
    top: 120px;
  }

  .stepsFour .stepArrows {
    transform: scaleX(1) !important;
    -webkit-transform: scaleX(1) !important;
    -moz-transform: scaleX(1) !important;
    -ms-transform: scaleX(1) !important;
    -o-transform: scaleX(1) !important;
    width: 470px;
    right: 0;
  }

  .rtl .stepsFour {
    left: -45px;
  }

  .rtl .stepsFour .stepNumImg {
    left: 20px;
    top: 96px;
  }

  .rtl .stepsFive .stepArrows {
    transform: scaleX(1) !important;
    -webkit-transform: scaleX(1) !important;
    -moz-transform: scaleX(1) !important;
    -ms-transform: scaleX(1) !important;
    -o-transform: scaleX(1) !important;
    left: 0;
    right: auto;
  }

  .rtl .stepsFive .stepNumImg {
    left: 240px;
    top: 160px;
  }

  .rtl .processFormWrapper {
    padding-bottom: 930px !important;
  }

  .rtl .stepContactWrapper {
    height: 940px;
  }

  .rtl .stepNumbersWrapTwo {
    top: 40px;
    left: 140px;
    right: auto !important;
  }

  .rtl .stepNumbersWrapFour {
    left: 40px !important;
    right: auto !important;
  }

  .rtl .stepNumbersWrapThree {
    top: 25px !important;
    left: 140px;
    right: auto !important;
  }

  .rtl .stepNumbersWrapFive {
    top: 0;
    left: 0 !important;
    right: -100px;
  }
}

@media (max-width: 768px) {

  .rtl .slick-prev,
  .rtl .slick-next {
    top: auto !important;
    bottom: -10px !important;
  }

  .rtl .slick-prev {
    left: 0 !important;
    right: -100px !important;
  }

  .rtl .slick-next {
    right: 0 !important;
    left: -100px !important;
  }

  .rtl .Tab-box .custom-img {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .rtl .stepNumbersWrapTwo {
    top: 0 !important;
    right: 0 !important;
  }

  .rtl .stepNumbersWrapThree {
    top: 0 !important;
    right: 0 !important;
  }

  .rtl .stepNumbersWrapFour {
    top: 0 !important;
    right: 0 !important;
  }
}

@media (max-width: 767px) {
  .rtl .stepContact {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    height: auto;
    margin-bottom: 30px;
  }

  .rtl .stepContact>*,
  .rtl .stepNumImg span {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
  }

  .rtl .StepNumbersTxt {
    font-size: 19px;
  }

  .rtl .stepContactWrapper {
    height: 470px !important;
    top: 560px;
  }

  .rtl .processFormWrapper {
    padding-bottom: 490px !important;
  }

  .rtl .processFormWrapper {
    padding-top: 69px !important;
  }

  .rtl .about-content {
    border-radius: 0 !important;
  }
}

@media (max-width: 576px) {

  .rtl .slick-prev,
  .rtl .slick-next {
    width: 40px !important;
    height: 40px !important;
    bottom: 50% !important;
  }

  .rtl .slick-next {
    right: 260px !important;
  }

  .rtl .slick-prev {
    left: 260px !important;

  }

  .rtl .processFormWrapper {
    padding-bottom: 320px !important;
  }

  .rtl .stepNumbersWrapFive {
    left: 0 !important;
    right: 0px !important;
    top: 0;
  }
}

@media (max-width: 480px) {
  .rtl .animated-wrapper {
    flex-direction: row-reverse !important;
    direction: ltr !important;
  }

  .rtl .slick-prev,
  .rtl .slick-next {
    width: 40px !important;
    height: 40px !important;
    bottom: 62% !important;
  }

  .rtl .slick-next {
    right: 200px !important;
  }

  .rtl .slick-prev {
    left: 200px !important;

  }

}

@media (max-width: 400px) {}