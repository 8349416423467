:root {
    --themeColor: #004F27;
    --darkgreen: #11271D;
    --lightgreen: #7BB800;
    --white: #F6FEE6;
    --whiteColor: #FFFFFF;
    --text: #A9BC97;
    --textPalceholder: #238152;
}


